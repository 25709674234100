
import { defineComponent } from 'vue'
import PhonePreview from '@/components/shared/templates/PhonePreview.vue'
import WhiteBlock from '@/components/shared/templates/WhiteBlock.vue'
import DetailsTableItem from '@/components/shared/templates/details/DetailsTableItem.vue'
import DetailsTable from '@/components/shared/templates/details/DetailsTable.vue'
import TmChip from '@/components/shared/TmChip.vue'
import TmCountry from '@/components/shared/TmCountry.vue'
import { campaignsEmailDestination } from '@/definitions/campaigns/data'
import CampaignsSmsDetails from '@/components/views/campaigns/sms/details/CampaignsSmsDetails.vue'

export default defineComponent({
  components: {
    CampaignsSmsDetails,
    TmChip,
    WhiteBlock,
    TmCountry,
    PhonePreview,
    DetailsTable,
    DetailsTableItem,
  },
  setup() {
    const message = 'Hello {First name}!\nMessage from: Level Up. Gentleman called again, said it is about calling up on a ticket.\nNo one is getting their texts. He has sent an email but no one has got back to him.\nReply “Stop” to opt out.'

    return {
      message,
      campaignsEmailDestination,
    }
  },
})
